import React from 'react';
import {Grid, Table, TableBody, TableCell, TableHead, TableRow, Typography} from '@material-ui/core';
import {compose} from 'recompose';
import {WeeklyCompetitionRegistration} from '@generated/schema';
import {
  FieldCustomValue,
  FieldValue, formatDateTime,
  Modal,
} from '@queensland-running/qr-components';
import {CompetitionRegistration, Maybe, Runner, Scalars} from "@queensland-running/qr-components/src/@generated/schema";
import {flow, map} from "lodash/fp";
import {MoodBad} from "@material-ui/icons";
import moment from "moment/moment";

type RegistrationModalProps = {
  registration: WeeklyCompetitionRegistration;
  competition: any;
  open: boolean;
  onClose: () => void;
};
type RegistrationModalModalViewProps = RegistrationModalProps;

const enhance = compose<RegistrationModalModalViewProps, RegistrationModalProps>(React.memo);

const RegistrationModalView = ({registration, competition, onClose, open}: RegistrationModalModalViewProps) => {
  const registeredDateTime = formatDateTime(registration.contactDetailsCollectedDate as number);
  return (
    <Modal
      onClose={onClose}
      open={open}
      title={'Registration Details'}
      primaryAction={onClose}
      primaryActionText={'Close'}
    >
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <FieldValue label="First Name" name="firstName" value={registration.contactDetails?.firstName}/>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FieldValue label="Last Name" name="lastName" value={registration.contactDetails?.lastName}/>
        </Grid>
        <Grid item xs={12}>
          <FieldValue label="Email" name="email" value={registration.username}/>
        </Grid>
        <Grid item xs={12}>
          <FieldCustomValue label="Runners" name="runners" CustomValue={() =>
            <Table>
              <TableHead><TableRow><TableCell>Name</TableCell><TableCell>Date of
                Birth</TableCell><TableCell>Bib</TableCell><TableCell>Club</TableCell></TableRow></TableHead>
              <TableBody>
                {
                  ((registration as any).allRunnerDetails ?? []).map((detail: any, index: number) =>
                    <TableRow key={index}><TableCell>{detail.lastName}, {detail.firstName}</TableCell><TableCell>{detail['dateOfBirth'] &&
                      `${detail['dateOfBirth'] && moment(detail['dateOfBirth']).format('DD/MM/YYYY')} (${moment(competition.day).diff(
                        detail['dateOfBirth'],
                        'years',
                      )} y/o)`}</TableCell><TableCell>{detail.bibNumber}</TableCell><TableCell>{detail.club}</TableCell></TableRow>
                  )
                }
              </TableBody>
            </Table>
          }/>
        </Grid>
      </Grid>
      <Typography variant="body2" color="textSecondary">Registered: {registeredDateTime}</Typography>
    </Modal>
  );
};

export const RegistrationDetailsModal = enhance(RegistrationModalView);
