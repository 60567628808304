/* eslint-disable */
import * as Types from '../../@generated/schema.d';

import { gql } from 'graphql.macro';
import * as Apollo from '@apollo/client';
import * as React from 'react';
import * as ApolloReactComponents from '@apollo/client/react/components';
import * as ApolloReactHoc from '@apollo/client/react/hoc';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type UploadResultsMutationVariables = Types.Exact<{
  input: Types.UploadResultsInput;
}>;

export type UploadResultsMutation = { readonly __typename?: 'Mutation' } & {
  readonly competition?: Types.Maybe<
    { readonly __typename?: 'CompetitionMutation' } & {
      readonly uploadResults: { readonly __typename?: 'UploadResultsResponse' } & Pick<
        Types.UploadResultsResponse,
        'success' | 'method' | 'fileUploadUrl'
      >;
    }
  >;
};

export type UploadCompetitionResultsMutationVariables = Types.Exact<{
  input: Types.UploadCompetitionResultsInput;
}>;

export type UploadCompetitionResultsMutation = { readonly __typename?: 'Mutation' } & {
  readonly competition?: Types.Maybe<
    { readonly __typename?: 'CompetitionMutation' } & {
      readonly uploadCompetitionResults: { readonly __typename?: 'UploadCompetitionResultsResponse' } & Pick<
        Types.UploadCompetitionResultsResponse,
        'success' | 'method' | 'fileUploadUrl'
      > & {
          readonly competition?: Types.Maybe<
            | ({ readonly __typename?: 'AllSchoolsChampionships' } & Pick<Types.AllSchoolsChampionships, 'id'>)
            | ({ readonly __typename?: 'NoCompetition' } & Pick<Types.NoCompetition, 'id'>)
            | ({ readonly __typename?: 'RockettRelays' } & Pick<Types.RockettRelays, 'id'>)
            | ({ readonly __typename?: 'WeeklyCompetition' } & Pick<Types.WeeklyCompetition, 'results' | 'id'>)
            | ({ readonly __typename?: 'WinterChampionships' } & Pick<Types.WinterChampionships, 'id'>)
          >;
        };
    }
  >;
};

export type DeleteCompetitionResultsMutationVariables = Types.Exact<{
  input: Types.DeleteCompetitionResultsInput;
}>;

export type DeleteCompetitionResultsMutation = { readonly __typename?: 'Mutation' } & {
  readonly competition?: Types.Maybe<
    { readonly __typename?: 'CompetitionMutation' } & {
      readonly deleteCompetitionResults: { readonly __typename?: 'DeleteCompetitionResultsResponse' } & {
        readonly competition?: Types.Maybe<
          | ({ readonly __typename?: 'AllSchoolsChampionships' } & Pick<Types.AllSchoolsChampionships, 'id'>)
          | ({ readonly __typename?: 'NoCompetition' } & Pick<Types.NoCompetition, 'id'>)
          | ({ readonly __typename?: 'RockettRelays' } & Pick<Types.RockettRelays, 'id'>)
          | ({ readonly __typename?: 'WeeklyCompetition' } & Pick<Types.WeeklyCompetition, 'results' | 'id'>)
          | ({ readonly __typename?: 'WinterChampionships' } & Pick<Types.WinterChampionships, 'id'>)
        >;
      };
    }
  >;
};

export type UpdateEventMutationVariables = Types.Exact<{
  input: Types.UpdateEventInput;
}>;

export type UpdateEventMutation = { readonly __typename?: 'Mutation' } & {
  readonly competition?: Types.Maybe<
    { readonly __typename?: 'CompetitionMutation' } & {
      readonly updateEvent:
        | ({ readonly __typename?: 'AllSchoolsChampionships' } & Pick<Types.AllSchoolsChampionships, 'id' | 'day'>)
        | ({ readonly __typename?: 'NoCompetition' } & Pick<Types.NoCompetition, 'id' | 'day'>)
        | ({ readonly __typename?: 'RockettRelays' } & Pick<Types.RockettRelays, 'id' | 'day'>)
        | ({ readonly __typename?: 'WeeklyCompetition' } & Pick<Types.WeeklyCompetition, 'id' | 'day'> & {
              readonly venue?: Types.Maybe<
                { readonly __typename?: 'Venue' } & Pick<Types.Venue, 'displayName' | 'name' | 'street' | 'url'>
              >;
            })
        | ({ readonly __typename?: 'WinterChampionships' } & Pick<Types.WinterChampionships, 'id' | 'day'>);
    }
  >;
};

export type CreateEventMutationVariables = Types.Exact<{
  input: Types.CreateEventInput;
}>;

export type CreateEventMutation = { readonly __typename?: 'Mutation' } & {
  readonly competition?: Types.Maybe<
    { readonly __typename?: 'CompetitionMutation' } & {
      readonly createEvent:
        | ({ readonly __typename?: 'AllSchoolsChampionships' } & Pick<Types.AllSchoolsChampionships, 'id' | 'day'>)
        | ({ readonly __typename?: 'NoCompetition' } & Pick<Types.NoCompetition, 'id' | 'day'>)
        | ({ readonly __typename?: 'RockettRelays' } & Pick<Types.RockettRelays, 'id' | 'day'>)
        | ({ readonly __typename?: 'WeeklyCompetition' } & Pick<Types.WeeklyCompetition, 'id' | 'day'> & {
              readonly venue?: Types.Maybe<
                { readonly __typename?: 'Venue' } & Pick<Types.Venue, 'name' | 'street' | 'url'>
              >;
            })
        | ({ readonly __typename?: 'WinterChampionships' } & Pick<Types.WinterChampionships, 'id' | 'day'>);
    }
  >;
};

export type DeleteResultsMutationVariables = Types.Exact<{
  input: Types.DeleteResultsInput;
}>;

export type DeleteResultsMutation = { readonly __typename?: 'Mutation' } & {
  readonly competition?: Types.Maybe<
    { readonly __typename?: 'CompetitionMutation' } & {
      readonly deleteResults: { readonly __typename: 'DeleteResultsResponse' };
    }
  >;
};

export type UpdateAdministratorMutationVariables = Types.Exact<{
  input: Types.UpdateAdministratorInput;
}>;

export type UpdateAdministratorMutation = { readonly __typename?: 'Mutation' } & {
  readonly administrator?: Types.Maybe<
    { readonly __typename?: 'AdministratorMutation' } & {
      readonly updateAdministrator?: Types.Maybe<
        { readonly __typename?: 'Administrator' } & Pick<
          Types.Administrator,
          'id' | 'firstName' | 'lastName' | 'name' | 'picture'
        > & {
            readonly roles?: Types.Maybe<
              ReadonlyArray<{ readonly __typename?: 'Role' } & Pick<Types.Role, 'id' | 'name' | 'description'>>
            >;
          }
      >;
    }
  >;
};

export type AdminUpdateRunnerMutationVariables = Types.Exact<{
  input: Types.AdminUpdateRunnerInput;
}>;

export type AdminUpdateRunnerMutation = { readonly __typename?: 'Mutation' } & {
  readonly runners?: Types.Maybe<
    { readonly __typename?: 'RunnersMutation' } & {
      readonly adminUpdateRunner?: Types.Maybe<
        { readonly __typename?: 'Runner' } & Pick<
          Types.Runner,
          | 'id'
          | 'firstName'
          | 'lastName'
          | 'dateOfBirth'
          | 'bibNumber'
          | 'club'
          | 'status'
          | 'membership'
          | 'memberships'
        >
      >;
    }
  >;
};

export type AdminUpdateEventRegistrationMutationVariables = Types.Exact<{
  input: Types.AdminUpdateEventRegistrationInput;
}>;

export type AdminUpdateEventRegistrationMutation = { readonly __typename?: 'Mutation' } & {
  readonly competition?: Types.Maybe<
    { readonly __typename?: 'CompetitionMutation' } & {
      readonly adminUpdateEventRegistration:
        | ({ readonly __typename?: 'AllSchoolsChampionshipsRegistration' } & Pick<
            Types.AllSchoolsChampionshipsRegistration,
            'id'
          >)
        | ({ readonly __typename?: 'RockettRelaysRegistration' } & Pick<Types.RockettRelaysRegistration, 'id'>)
        | ({ readonly __typename?: 'WeeklyCompetitionRegistration' } & Pick<Types.WeeklyCompetitionRegistration, 'id'>);
    }
  >;
};

export type DeleteRunnerMutationVariables = Types.Exact<{
  input: Types.AdminDeleteRunnerInput;
}>;

export type DeleteRunnerMutation = { readonly __typename?: 'Mutation' } & {
  readonly runners?: Types.Maybe<
    { readonly __typename?: 'RunnersMutation' } & {
      readonly adminDeleteRunner?: Types.Maybe<
        { readonly __typename?: 'Runner' } & Pick<Types.Runner, 'id' | 'bibNumber' | 'status'>
      >;
    }
  >;
};

export type ChangePasswordMutationVariables = Types.Exact<{
  input: Types.ChangePasswordInput;
}>;

export type ChangePasswordMutation = { readonly __typename?: 'Mutation' } & {
  readonly administrator?: Types.Maybe<
    { readonly __typename?: 'AdministratorMutation' } & {
      readonly changePassword?: Types.Maybe<
        { readonly __typename?: 'Administrator' } & Pick<Types.Administrator, 'id'>
      >;
    }
  >;
};

export type CreateRockettRelayTeamMutationVariables = Types.Exact<{
  input: Types.RegisterTeamForRockettRelaysInputPartial;
}>;

export type CreateRockettRelayTeamMutation = { readonly __typename?: 'Mutation' } & {
  readonly competition?: Types.Maybe<
    { readonly __typename?: 'CompetitionMutation' } & {
      readonly registerTeamForRockettRelays: { readonly __typename?: 'RockettRelaysRegistration' } & Pick<
        Types.RockettRelaysRegistration,
        'id' | 'competitionId'
      > & {
          readonly teamDetails?: Types.Maybe<
            { readonly __typename?: 'TeamDetails' } & Pick<
              Types.TeamDetails,
              'relay' | 'teamName' | 'ageGroup' | 'gender'
            >
          >;
          readonly runnerDetails?: Types.Maybe<
            ReadonlyArray<
              { readonly __typename?: 'RockettRelaysRunnerDetails' } & Pick<
                Types.RockettRelaysRunnerDetails,
                'name' | 'position' | 'dateOfBirth'
              >
            >
          >;
        };
    }
  >;
};

export type UpdateRockettRelaysFessMutationVariables = Types.Exact<{
  input: Types.UpdateRockettRelaysFeesInput;
}>;

export type UpdateRockettRelaysFessMutation = { readonly __typename?: 'Mutation' } & {
  readonly competition?: Types.Maybe<
    { readonly __typename?: 'CompetitionMutation' } & {
      readonly updateRockettRelaysFees?: Types.Maybe<
        { readonly __typename?: 'RockettRelays' } & Pick<Types.RockettRelays, 'id'> & {
            readonly fees?: Types.Maybe<
              { readonly __typename?: 'RockettRelaysFees' } & {
                readonly ONE_HOUR_RELAY: { readonly __typename?: 'RockettRelaysRelayFee' } & Pick<
                  Types.RockettRelaysRelayFee,
                  'weeklyCompetitor' | 'nonWeeklyCompetitor'
                >;
                readonly TWO_HOUR_RELAY: { readonly __typename?: 'RockettRelaysRelayFee' } & Pick<
                  Types.RockettRelaysRelayFee,
                  'weeklyCompetitor' | 'nonWeeklyCompetitor'
                >;
              }
            >;
          }
      >;
    }
  >;
};

export const UploadResultsDocument = gql`
  mutation uploadResults($input: UploadResultsInput!) {
    competition {
      uploadResults(input: $input) {
        success
        method
        fileUploadUrl
      }
    }
  }
`;
export type UploadResultsMutationFn = Apollo.MutationFunction<UploadResultsMutation, UploadResultsMutationVariables>;
export type UploadResultsComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<UploadResultsMutation, UploadResultsMutationVariables>,
  'mutation'
>;

export const UploadResultsComponent = (props: UploadResultsComponentProps) => (
  <ApolloReactComponents.Mutation<UploadResultsMutation, UploadResultsMutationVariables>
    mutation={UploadResultsDocument}
    {...props}
  />
);

export type UploadResultsProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
  [key in TDataName]: Apollo.MutationFunction<UploadResultsMutation, UploadResultsMutationVariables>;
} &
  TChildProps;
export function withUploadResults<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UploadResultsMutation,
    UploadResultsMutationVariables,
    UploadResultsProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UploadResultsMutation,
    UploadResultsMutationVariables,
    UploadResultsProps<TChildProps, TDataName>
  >(UploadResultsDocument, {
    alias: 'uploadResults',
    ...operationOptions,
  });
}

/**
 * __useUploadResultsMutation__
 *
 * To run a mutation, you first call `useUploadResultsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadResultsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadResultsMutation, { data, loading, error }] = useUploadResultsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUploadResultsMutation(
  baseOptions?: Apollo.MutationHookOptions<UploadResultsMutation, UploadResultsMutationVariables>,
) {
  return Apollo.useMutation<UploadResultsMutation, UploadResultsMutationVariables>(UploadResultsDocument, baseOptions);
}
export type UploadResultsMutationHookResult = ReturnType<typeof useUploadResultsMutation>;
export type UploadResultsMutationResult = Apollo.MutationResult<UploadResultsMutation>;
export type UploadResultsMutationOptions = Apollo.BaseMutationOptions<
  UploadResultsMutation,
  UploadResultsMutationVariables
>;
export const UploadCompetitionResultsDocument = gql`
  mutation uploadCompetitionResults($input: UploadCompetitionResultsInput!) {
    competition {
      uploadCompetitionResults(input: $input) {
        success
        method
        fileUploadUrl
        competition {
          id
          ... on WeeklyCompetition {
            results
          }
        }
      }
    }
  }
`;
export type UploadCompetitionResultsMutationFn = Apollo.MutationFunction<
  UploadCompetitionResultsMutation,
  UploadCompetitionResultsMutationVariables
>;
export type UploadCompetitionResultsComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    UploadCompetitionResultsMutation,
    UploadCompetitionResultsMutationVariables
  >,
  'mutation'
>;

export const UploadCompetitionResultsComponent = (props: UploadCompetitionResultsComponentProps) => (
  <ApolloReactComponents.Mutation<UploadCompetitionResultsMutation, UploadCompetitionResultsMutationVariables>
    mutation={UploadCompetitionResultsDocument}
    {...props}
  />
);

export type UploadCompetitionResultsProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
  [key in TDataName]: Apollo.MutationFunction<
    UploadCompetitionResultsMutation,
    UploadCompetitionResultsMutationVariables
  >;
} &
  TChildProps;
export function withUploadCompetitionResults<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UploadCompetitionResultsMutation,
    UploadCompetitionResultsMutationVariables,
    UploadCompetitionResultsProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UploadCompetitionResultsMutation,
    UploadCompetitionResultsMutationVariables,
    UploadCompetitionResultsProps<TChildProps, TDataName>
  >(UploadCompetitionResultsDocument, {
    alias: 'uploadCompetitionResults',
    ...operationOptions,
  });
}

/**
 * __useUploadCompetitionResultsMutation__
 *
 * To run a mutation, you first call `useUploadCompetitionResultsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadCompetitionResultsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadCompetitionResultsMutation, { data, loading, error }] = useUploadCompetitionResultsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUploadCompetitionResultsMutation(
  baseOptions?: Apollo.MutationHookOptions<UploadCompetitionResultsMutation, UploadCompetitionResultsMutationVariables>,
) {
  return Apollo.useMutation<UploadCompetitionResultsMutation, UploadCompetitionResultsMutationVariables>(
    UploadCompetitionResultsDocument,
    baseOptions,
  );
}
export type UploadCompetitionResultsMutationHookResult = ReturnType<typeof useUploadCompetitionResultsMutation>;
export type UploadCompetitionResultsMutationResult = Apollo.MutationResult<UploadCompetitionResultsMutation>;
export type UploadCompetitionResultsMutationOptions = Apollo.BaseMutationOptions<
  UploadCompetitionResultsMutation,
  UploadCompetitionResultsMutationVariables
>;
export const DeleteCompetitionResultsDocument = gql`
  mutation deleteCompetitionResults($input: DeleteCompetitionResultsInput!) {
    competition {
      deleteCompetitionResults(input: $input) {
        competition {
          id
          ... on WeeklyCompetition {
            results
          }
        }
      }
    }
  }
`;
export type DeleteCompetitionResultsMutationFn = Apollo.MutationFunction<
  DeleteCompetitionResultsMutation,
  DeleteCompetitionResultsMutationVariables
>;
export type DeleteCompetitionResultsComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    DeleteCompetitionResultsMutation,
    DeleteCompetitionResultsMutationVariables
  >,
  'mutation'
>;

export const DeleteCompetitionResultsComponent = (props: DeleteCompetitionResultsComponentProps) => (
  <ApolloReactComponents.Mutation<DeleteCompetitionResultsMutation, DeleteCompetitionResultsMutationVariables>
    mutation={DeleteCompetitionResultsDocument}
    {...props}
  />
);

export type DeleteCompetitionResultsProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
  [key in TDataName]: Apollo.MutationFunction<
    DeleteCompetitionResultsMutation,
    DeleteCompetitionResultsMutationVariables
  >;
} &
  TChildProps;
export function withDeleteCompetitionResults<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    DeleteCompetitionResultsMutation,
    DeleteCompetitionResultsMutationVariables,
    DeleteCompetitionResultsProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withMutation<
    TProps,
    DeleteCompetitionResultsMutation,
    DeleteCompetitionResultsMutationVariables,
    DeleteCompetitionResultsProps<TChildProps, TDataName>
  >(DeleteCompetitionResultsDocument, {
    alias: 'deleteCompetitionResults',
    ...operationOptions,
  });
}

/**
 * __useDeleteCompetitionResultsMutation__
 *
 * To run a mutation, you first call `useDeleteCompetitionResultsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCompetitionResultsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCompetitionResultsMutation, { data, loading, error }] = useDeleteCompetitionResultsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteCompetitionResultsMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteCompetitionResultsMutation, DeleteCompetitionResultsMutationVariables>,
) {
  return Apollo.useMutation<DeleteCompetitionResultsMutation, DeleteCompetitionResultsMutationVariables>(
    DeleteCompetitionResultsDocument,
    baseOptions,
  );
}
export type DeleteCompetitionResultsMutationHookResult = ReturnType<typeof useDeleteCompetitionResultsMutation>;
export type DeleteCompetitionResultsMutationResult = Apollo.MutationResult<DeleteCompetitionResultsMutation>;
export type DeleteCompetitionResultsMutationOptions = Apollo.BaseMutationOptions<
  DeleteCompetitionResultsMutation,
  DeleteCompetitionResultsMutationVariables
>;
export const UpdateEventDocument = gql`
  mutation updateEvent($input: UpdateEventInput!) {
    competition {
      updateEvent(input: $input) {
        id
        day
        ... on WeeklyCompetition {
          venue {
            displayName
            name
            street
            url
          }
        }
      }
    }
  }
`;
export type UpdateEventMutationFn = Apollo.MutationFunction<UpdateEventMutation, UpdateEventMutationVariables>;
export type UpdateEventComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<UpdateEventMutation, UpdateEventMutationVariables>,
  'mutation'
>;

export const UpdateEventComponent = (props: UpdateEventComponentProps) => (
  <ApolloReactComponents.Mutation<UpdateEventMutation, UpdateEventMutationVariables>
    mutation={UpdateEventDocument}
    {...props}
  />
);

export type UpdateEventProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
  [key in TDataName]: Apollo.MutationFunction<UpdateEventMutation, UpdateEventMutationVariables>;
} &
  TChildProps;
export function withUpdateEvent<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UpdateEventMutation,
    UpdateEventMutationVariables,
    UpdateEventProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UpdateEventMutation,
    UpdateEventMutationVariables,
    UpdateEventProps<TChildProps, TDataName>
  >(UpdateEventDocument, {
    alias: 'updateEvent',
    ...operationOptions,
  });
}

/**
 * __useUpdateEventMutation__
 *
 * To run a mutation, you first call `useUpdateEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEventMutation, { data, loading, error }] = useUpdateEventMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateEventMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateEventMutation, UpdateEventMutationVariables>,
) {
  return Apollo.useMutation<UpdateEventMutation, UpdateEventMutationVariables>(UpdateEventDocument, baseOptions);
}
export type UpdateEventMutationHookResult = ReturnType<typeof useUpdateEventMutation>;
export type UpdateEventMutationResult = Apollo.MutationResult<UpdateEventMutation>;
export type UpdateEventMutationOptions = Apollo.BaseMutationOptions<UpdateEventMutation, UpdateEventMutationVariables>;
export const CreateEventDocument = gql`
  mutation createEvent($input: CreateEventInput!) {
    competition {
      createEvent(input: $input) {
        id
        day
        ... on WeeklyCompetition {
          venue {
            name
            street
            url
          }
        }
      }
    }
  }
`;
export type CreateEventMutationFn = Apollo.MutationFunction<CreateEventMutation, CreateEventMutationVariables>;
export type CreateEventComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<CreateEventMutation, CreateEventMutationVariables>,
  'mutation'
>;

export const CreateEventComponent = (props: CreateEventComponentProps) => (
  <ApolloReactComponents.Mutation<CreateEventMutation, CreateEventMutationVariables>
    mutation={CreateEventDocument}
    {...props}
  />
);

export type CreateEventProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
  [key in TDataName]: Apollo.MutationFunction<CreateEventMutation, CreateEventMutationVariables>;
} &
  TChildProps;
export function withCreateEvent<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CreateEventMutation,
    CreateEventMutationVariables,
    CreateEventProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withMutation<
    TProps,
    CreateEventMutation,
    CreateEventMutationVariables,
    CreateEventProps<TChildProps, TDataName>
  >(CreateEventDocument, {
    alias: 'createEvent',
    ...operationOptions,
  });
}

/**
 * __useCreateEventMutation__
 *
 * To run a mutation, you first call `useCreateEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEventMutation, { data, loading, error }] = useCreateEventMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateEventMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateEventMutation, CreateEventMutationVariables>,
) {
  return Apollo.useMutation<CreateEventMutation, CreateEventMutationVariables>(CreateEventDocument, baseOptions);
}
export type CreateEventMutationHookResult = ReturnType<typeof useCreateEventMutation>;
export type CreateEventMutationResult = Apollo.MutationResult<CreateEventMutation>;
export type CreateEventMutationOptions = Apollo.BaseMutationOptions<CreateEventMutation, CreateEventMutationVariables>;
export const DeleteResultsDocument = gql`
  mutation deleteResults($input: DeleteResultsInput!) {
    competition {
      deleteResults(input: $input) {
        __typename
      }
    }
  }
`;
export type DeleteResultsMutationFn = Apollo.MutationFunction<DeleteResultsMutation, DeleteResultsMutationVariables>;
export type DeleteResultsComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<DeleteResultsMutation, DeleteResultsMutationVariables>,
  'mutation'
>;

export const DeleteResultsComponent = (props: DeleteResultsComponentProps) => (
  <ApolloReactComponents.Mutation<DeleteResultsMutation, DeleteResultsMutationVariables>
    mutation={DeleteResultsDocument}
    {...props}
  />
);

export type DeleteResultsProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
  [key in TDataName]: Apollo.MutationFunction<DeleteResultsMutation, DeleteResultsMutationVariables>;
} &
  TChildProps;
export function withDeleteResults<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    DeleteResultsMutation,
    DeleteResultsMutationVariables,
    DeleteResultsProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withMutation<
    TProps,
    DeleteResultsMutation,
    DeleteResultsMutationVariables,
    DeleteResultsProps<TChildProps, TDataName>
  >(DeleteResultsDocument, {
    alias: 'deleteResults',
    ...operationOptions,
  });
}

/**
 * __useDeleteResultsMutation__
 *
 * To run a mutation, you first call `useDeleteResultsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteResultsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteResultsMutation, { data, loading, error }] = useDeleteResultsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteResultsMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteResultsMutation, DeleteResultsMutationVariables>,
) {
  return Apollo.useMutation<DeleteResultsMutation, DeleteResultsMutationVariables>(DeleteResultsDocument, baseOptions);
}
export type DeleteResultsMutationHookResult = ReturnType<typeof useDeleteResultsMutation>;
export type DeleteResultsMutationResult = Apollo.MutationResult<DeleteResultsMutation>;
export type DeleteResultsMutationOptions = Apollo.BaseMutationOptions<
  DeleteResultsMutation,
  DeleteResultsMutationVariables
>;
export const UpdateAdministratorDocument = gql`
  mutation updateAdministrator($input: UpdateAdministratorInput!) {
    administrator {
      updateAdministrator(input: $input) {
        id
        firstName
        lastName
        name
        picture
        roles {
          id
          name
          description
        }
      }
    }
  }
`;
export type UpdateAdministratorMutationFn = Apollo.MutationFunction<
  UpdateAdministratorMutation,
  UpdateAdministratorMutationVariables
>;
export type UpdateAdministratorComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<UpdateAdministratorMutation, UpdateAdministratorMutationVariables>,
  'mutation'
>;

export const UpdateAdministratorComponent = (props: UpdateAdministratorComponentProps) => (
  <ApolloReactComponents.Mutation<UpdateAdministratorMutation, UpdateAdministratorMutationVariables>
    mutation={UpdateAdministratorDocument}
    {...props}
  />
);

export type UpdateAdministratorProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
  [key in TDataName]: Apollo.MutationFunction<UpdateAdministratorMutation, UpdateAdministratorMutationVariables>;
} &
  TChildProps;
export function withUpdateAdministrator<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UpdateAdministratorMutation,
    UpdateAdministratorMutationVariables,
    UpdateAdministratorProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UpdateAdministratorMutation,
    UpdateAdministratorMutationVariables,
    UpdateAdministratorProps<TChildProps, TDataName>
  >(UpdateAdministratorDocument, {
    alias: 'updateAdministrator',
    ...operationOptions,
  });
}

/**
 * __useUpdateAdministratorMutation__
 *
 * To run a mutation, you first call `useUpdateAdministratorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAdministratorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAdministratorMutation, { data, loading, error }] = useUpdateAdministratorMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAdministratorMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateAdministratorMutation, UpdateAdministratorMutationVariables>,
) {
  return Apollo.useMutation<UpdateAdministratorMutation, UpdateAdministratorMutationVariables>(
    UpdateAdministratorDocument,
    baseOptions,
  );
}
export type UpdateAdministratorMutationHookResult = ReturnType<typeof useUpdateAdministratorMutation>;
export type UpdateAdministratorMutationResult = Apollo.MutationResult<UpdateAdministratorMutation>;
export type UpdateAdministratorMutationOptions = Apollo.BaseMutationOptions<
  UpdateAdministratorMutation,
  UpdateAdministratorMutationVariables
>;
export const AdminUpdateRunnerDocument = gql`
  mutation adminUpdateRunner($input: AdminUpdateRunnerInput!) {
    runners {
      adminUpdateRunner(input: $input) {
        id
        firstName
        lastName
        dateOfBirth
        bibNumber
        club
        status
        membership
        memberships
      }
    }
  }
`;
export type AdminUpdateRunnerMutationFn = Apollo.MutationFunction<
  AdminUpdateRunnerMutation,
  AdminUpdateRunnerMutationVariables
>;
export type AdminUpdateRunnerComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<AdminUpdateRunnerMutation, AdminUpdateRunnerMutationVariables>,
  'mutation'
>;

export const AdminUpdateRunnerComponent = (props: AdminUpdateRunnerComponentProps) => (
  <ApolloReactComponents.Mutation<AdminUpdateRunnerMutation, AdminUpdateRunnerMutationVariables>
    mutation={AdminUpdateRunnerDocument}
    {...props}
  />
);

export type AdminUpdateRunnerProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
  [key in TDataName]: Apollo.MutationFunction<AdminUpdateRunnerMutation, AdminUpdateRunnerMutationVariables>;
} &
  TChildProps;
export function withAdminUpdateRunner<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    AdminUpdateRunnerMutation,
    AdminUpdateRunnerMutationVariables,
    AdminUpdateRunnerProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withMutation<
    TProps,
    AdminUpdateRunnerMutation,
    AdminUpdateRunnerMutationVariables,
    AdminUpdateRunnerProps<TChildProps, TDataName>
  >(AdminUpdateRunnerDocument, {
    alias: 'adminUpdateRunner',
    ...operationOptions,
  });
}

/**
 * __useAdminUpdateRunnerMutation__
 *
 * To run a mutation, you first call `useAdminUpdateRunnerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminUpdateRunnerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminUpdateRunnerMutation, { data, loading, error }] = useAdminUpdateRunnerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAdminUpdateRunnerMutation(
  baseOptions?: Apollo.MutationHookOptions<AdminUpdateRunnerMutation, AdminUpdateRunnerMutationVariables>,
) {
  return Apollo.useMutation<AdminUpdateRunnerMutation, AdminUpdateRunnerMutationVariables>(
    AdminUpdateRunnerDocument,
    baseOptions,
  );
}
export type AdminUpdateRunnerMutationHookResult = ReturnType<typeof useAdminUpdateRunnerMutation>;
export type AdminUpdateRunnerMutationResult = Apollo.MutationResult<AdminUpdateRunnerMutation>;
export type AdminUpdateRunnerMutationOptions = Apollo.BaseMutationOptions<
  AdminUpdateRunnerMutation,
  AdminUpdateRunnerMutationVariables
>;
export const AdminUpdateEventRegistrationDocument = gql`
  mutation adminUpdateEventRegistration($input: AdminUpdateEventRegistrationInput!) {
    competition {
      adminUpdateEventRegistration(input: $input) {
        id
      }
    }
  }
`;
export type AdminUpdateEventRegistrationMutationFn = Apollo.MutationFunction<
  AdminUpdateEventRegistrationMutation,
  AdminUpdateEventRegistrationMutationVariables
>;
export type AdminUpdateEventRegistrationComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    AdminUpdateEventRegistrationMutation,
    AdminUpdateEventRegistrationMutationVariables
  >,
  'mutation'
>;

export const AdminUpdateEventRegistrationComponent = (props: AdminUpdateEventRegistrationComponentProps) => (
  <ApolloReactComponents.Mutation<AdminUpdateEventRegistrationMutation, AdminUpdateEventRegistrationMutationVariables>
    mutation={AdminUpdateEventRegistrationDocument}
    {...props}
  />
);

export type AdminUpdateEventRegistrationProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
  [key in TDataName]: Apollo.MutationFunction<
    AdminUpdateEventRegistrationMutation,
    AdminUpdateEventRegistrationMutationVariables
  >;
} &
  TChildProps;
export function withAdminUpdateEventRegistration<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    AdminUpdateEventRegistrationMutation,
    AdminUpdateEventRegistrationMutationVariables,
    AdminUpdateEventRegistrationProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withMutation<
    TProps,
    AdminUpdateEventRegistrationMutation,
    AdminUpdateEventRegistrationMutationVariables,
    AdminUpdateEventRegistrationProps<TChildProps, TDataName>
  >(AdminUpdateEventRegistrationDocument, {
    alias: 'adminUpdateEventRegistration',
    ...operationOptions,
  });
}

/**
 * __useAdminUpdateEventRegistrationMutation__
 *
 * To run a mutation, you first call `useAdminUpdateEventRegistrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminUpdateEventRegistrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminUpdateEventRegistrationMutation, { data, loading, error }] = useAdminUpdateEventRegistrationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAdminUpdateEventRegistrationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AdminUpdateEventRegistrationMutation,
    AdminUpdateEventRegistrationMutationVariables
  >,
) {
  return Apollo.useMutation<AdminUpdateEventRegistrationMutation, AdminUpdateEventRegistrationMutationVariables>(
    AdminUpdateEventRegistrationDocument,
    baseOptions,
  );
}
export type AdminUpdateEventRegistrationMutationHookResult = ReturnType<typeof useAdminUpdateEventRegistrationMutation>;
export type AdminUpdateEventRegistrationMutationResult = Apollo.MutationResult<AdminUpdateEventRegistrationMutation>;
export type AdminUpdateEventRegistrationMutationOptions = Apollo.BaseMutationOptions<
  AdminUpdateEventRegistrationMutation,
  AdminUpdateEventRegistrationMutationVariables
>;
export const DeleteRunnerDocument = gql`
  mutation deleteRunner($input: AdminDeleteRunnerInput!) {
    runners {
      adminDeleteRunner(input: $input) {
        id
        bibNumber
        status
      }
    }
  }
`;
export type DeleteRunnerMutationFn = Apollo.MutationFunction<DeleteRunnerMutation, DeleteRunnerMutationVariables>;
export type DeleteRunnerComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<DeleteRunnerMutation, DeleteRunnerMutationVariables>,
  'mutation'
>;

export const DeleteRunnerComponent = (props: DeleteRunnerComponentProps) => (
  <ApolloReactComponents.Mutation<DeleteRunnerMutation, DeleteRunnerMutationVariables>
    mutation={DeleteRunnerDocument}
    {...props}
  />
);

export type DeleteRunnerProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
  [key in TDataName]: Apollo.MutationFunction<DeleteRunnerMutation, DeleteRunnerMutationVariables>;
} &
  TChildProps;
export function withDeleteRunner<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    DeleteRunnerMutation,
    DeleteRunnerMutationVariables,
    DeleteRunnerProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withMutation<
    TProps,
    DeleteRunnerMutation,
    DeleteRunnerMutationVariables,
    DeleteRunnerProps<TChildProps, TDataName>
  >(DeleteRunnerDocument, {
    alias: 'deleteRunner',
    ...operationOptions,
  });
}

/**
 * __useDeleteRunnerMutation__
 *
 * To run a mutation, you first call `useDeleteRunnerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteRunnerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteRunnerMutation, { data, loading, error }] = useDeleteRunnerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteRunnerMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteRunnerMutation, DeleteRunnerMutationVariables>,
) {
  return Apollo.useMutation<DeleteRunnerMutation, DeleteRunnerMutationVariables>(DeleteRunnerDocument, baseOptions);
}
export type DeleteRunnerMutationHookResult = ReturnType<typeof useDeleteRunnerMutation>;
export type DeleteRunnerMutationResult = Apollo.MutationResult<DeleteRunnerMutation>;
export type DeleteRunnerMutationOptions = Apollo.BaseMutationOptions<
  DeleteRunnerMutation,
  DeleteRunnerMutationVariables
>;
export const ChangePasswordDocument = gql`
  mutation changePassword($input: ChangePasswordInput!) {
    administrator {
      changePassword(input: $input) {
        id
      }
    }
  }
`;
export type ChangePasswordMutationFn = Apollo.MutationFunction<ChangePasswordMutation, ChangePasswordMutationVariables>;
export type ChangePasswordComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<ChangePasswordMutation, ChangePasswordMutationVariables>,
  'mutation'
>;

export const ChangePasswordComponent = (props: ChangePasswordComponentProps) => (
  <ApolloReactComponents.Mutation<ChangePasswordMutation, ChangePasswordMutationVariables>
    mutation={ChangePasswordDocument}
    {...props}
  />
);

export type ChangePasswordProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
  [key in TDataName]: Apollo.MutationFunction<ChangePasswordMutation, ChangePasswordMutationVariables>;
} &
  TChildProps;
export function withChangePassword<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    ChangePasswordMutation,
    ChangePasswordMutationVariables,
    ChangePasswordProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withMutation<
    TProps,
    ChangePasswordMutation,
    ChangePasswordMutationVariables,
    ChangePasswordProps<TChildProps, TDataName>
  >(ChangePasswordDocument, {
    alias: 'changePassword',
    ...operationOptions,
  });
}

/**
 * __useChangePasswordMutation__
 *
 * To run a mutation, you first call `useChangePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changePasswordMutation, { data, loading, error }] = useChangePasswordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useChangePasswordMutation(
  baseOptions?: Apollo.MutationHookOptions<ChangePasswordMutation, ChangePasswordMutationVariables>,
) {
  return Apollo.useMutation<ChangePasswordMutation, ChangePasswordMutationVariables>(
    ChangePasswordDocument,
    baseOptions,
  );
}
export type ChangePasswordMutationHookResult = ReturnType<typeof useChangePasswordMutation>;
export type ChangePasswordMutationResult = Apollo.MutationResult<ChangePasswordMutation>;
export type ChangePasswordMutationOptions = Apollo.BaseMutationOptions<
  ChangePasswordMutation,
  ChangePasswordMutationVariables
>;
export const CreateRockettRelayTeamDocument = gql`
  mutation createRockettRelayTeam($input: RegisterTeamForRockettRelaysInputPartial!) {
    competition {
      registerTeamForRockettRelays(input: $input) {
        id
        competitionId
        ... on RockettRelaysRegistration {
          teamDetails {
            relay
            teamName
            ageGroup
            gender
          }
          runnerDetails {
            name
            position
            dateOfBirth
          }
        }
      }
    }
  }
`;
export type CreateRockettRelayTeamMutationFn = Apollo.MutationFunction<
  CreateRockettRelayTeamMutation,
  CreateRockettRelayTeamMutationVariables
>;
export type CreateRockettRelayTeamComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    CreateRockettRelayTeamMutation,
    CreateRockettRelayTeamMutationVariables
  >,
  'mutation'
>;

export const CreateRockettRelayTeamComponent = (props: CreateRockettRelayTeamComponentProps) => (
  <ApolloReactComponents.Mutation<CreateRockettRelayTeamMutation, CreateRockettRelayTeamMutationVariables>
    mutation={CreateRockettRelayTeamDocument}
    {...props}
  />
);

export type CreateRockettRelayTeamProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
  [key in TDataName]: Apollo.MutationFunction<CreateRockettRelayTeamMutation, CreateRockettRelayTeamMutationVariables>;
} &
  TChildProps;
export function withCreateRockettRelayTeam<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CreateRockettRelayTeamMutation,
    CreateRockettRelayTeamMutationVariables,
    CreateRockettRelayTeamProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withMutation<
    TProps,
    CreateRockettRelayTeamMutation,
    CreateRockettRelayTeamMutationVariables,
    CreateRockettRelayTeamProps<TChildProps, TDataName>
  >(CreateRockettRelayTeamDocument, {
    alias: 'createRockettRelayTeam',
    ...operationOptions,
  });
}

/**
 * __useCreateRockettRelayTeamMutation__
 *
 * To run a mutation, you first call `useCreateRockettRelayTeamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRockettRelayTeamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRockettRelayTeamMutation, { data, loading, error }] = useCreateRockettRelayTeamMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateRockettRelayTeamMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateRockettRelayTeamMutation, CreateRockettRelayTeamMutationVariables>,
) {
  return Apollo.useMutation<CreateRockettRelayTeamMutation, CreateRockettRelayTeamMutationVariables>(
    CreateRockettRelayTeamDocument,
    baseOptions,
  );
}
export type CreateRockettRelayTeamMutationHookResult = ReturnType<typeof useCreateRockettRelayTeamMutation>;
export type CreateRockettRelayTeamMutationResult = Apollo.MutationResult<CreateRockettRelayTeamMutation>;
export type CreateRockettRelayTeamMutationOptions = Apollo.BaseMutationOptions<
  CreateRockettRelayTeamMutation,
  CreateRockettRelayTeamMutationVariables
>;
export const UpdateRockettRelaysFessDocument = gql`
  mutation updateRockettRelaysFess($input: UpdateRockettRelaysFeesInput!) {
    competition {
      updateRockettRelaysFees(input: $input) {
        id
        fees {
          ONE_HOUR_RELAY {
            weeklyCompetitor
            nonWeeklyCompetitor
          }
          TWO_HOUR_RELAY {
            weeklyCompetitor
            nonWeeklyCompetitor
          }
        }
      }
    }
  }
`;
export type UpdateRockettRelaysFessMutationFn = Apollo.MutationFunction<
  UpdateRockettRelaysFessMutation,
  UpdateRockettRelaysFessMutationVariables
>;
export type UpdateRockettRelaysFessComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    UpdateRockettRelaysFessMutation,
    UpdateRockettRelaysFessMutationVariables
  >,
  'mutation'
>;

export const UpdateRockettRelaysFessComponent = (props: UpdateRockettRelaysFessComponentProps) => (
  <ApolloReactComponents.Mutation<UpdateRockettRelaysFessMutation, UpdateRockettRelaysFessMutationVariables>
    mutation={UpdateRockettRelaysFessDocument}
    {...props}
  />
);

export type UpdateRockettRelaysFessProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
  [key in TDataName]: Apollo.MutationFunction<
    UpdateRockettRelaysFessMutation,
    UpdateRockettRelaysFessMutationVariables
  >;
} &
  TChildProps;
export function withUpdateRockettRelaysFess<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UpdateRockettRelaysFessMutation,
    UpdateRockettRelaysFessMutationVariables,
    UpdateRockettRelaysFessProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UpdateRockettRelaysFessMutation,
    UpdateRockettRelaysFessMutationVariables,
    UpdateRockettRelaysFessProps<TChildProps, TDataName>
  >(UpdateRockettRelaysFessDocument, {
    alias: 'updateRockettRelaysFess',
    ...operationOptions,
  });
}

/**
 * __useUpdateRockettRelaysFessMutation__
 *
 * To run a mutation, you first call `useUpdateRockettRelaysFessMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRockettRelaysFessMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRockettRelaysFessMutation, { data, loading, error }] = useUpdateRockettRelaysFessMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateRockettRelaysFessMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateRockettRelaysFessMutation, UpdateRockettRelaysFessMutationVariables>,
) {
  return Apollo.useMutation<UpdateRockettRelaysFessMutation, UpdateRockettRelaysFessMutationVariables>(
    UpdateRockettRelaysFessDocument,
    baseOptions,
  );
}
export type UpdateRockettRelaysFessMutationHookResult = ReturnType<typeof useUpdateRockettRelaysFessMutation>;
export type UpdateRockettRelaysFessMutationResult = Apollo.MutationResult<UpdateRockettRelaysFessMutation>;
export type UpdateRockettRelaysFessMutationOptions = Apollo.BaseMutationOptions<
  UpdateRockettRelaysFessMutation,
  UpdateRockettRelaysFessMutationVariables
>;
